@import "styles/variables";

.btn {
  padding: 12px 16px;
  min-width: 64px;
  max-width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  font-weight: 400;
  line-height: 100%;
  text-transform: uppercase;
  text-decoration: none;
  border-radius: 4px;
  opacity: 1;
  transition: color 0.2s ease-in, opacity 0.2s ease-in,
    background-color 0.2s ease-in;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }

  &--primary {
    color: $white;
    background-color: $primary;
    border: 1px solid $primary;
  }

  &--secondary {
    color: $black;
    background-color: $gray-light;
    border: 1px solid $gray-light;
  }
}
