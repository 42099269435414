$white: #ffffff;
$black: #14181c;

$primary: #05386b;

$gray-light: rgb(245, 245, 246);
$gray: #747579;

$red: #dd7973;

$whatsapp-base-color: #25d366;
$viber-base-color: #7360f2;
