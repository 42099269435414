@import "styles/variables";
@import "styles/mixins";

.footer {
  background-color: $gray-light;
  margin-left: 0;
  margin-right: 0;
  max-width: 100%;

  &__container {
    @include respond-to(from-md) {
      padding: 24px 55px;
      max-width: 1540px;
      margin: 0 auto;
    }

    @include respond-to(below-md) {
      padding: 24px;
    }
  }

  &__title {
    margin-bottom: 8px;
    color: $black;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }

  &__text {
    margin-bottom: 12px;
    color: $gray;
    font-size: 14px;
    font-weight: 300;
    line-height: 24px;
  }

  &__link {
    margin-bottom: 8px;
    display: block;
    color: $black;
    text-decoration: none;
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
  }
}

.footer-social {
  margin-top: 18px;
  display: flex;
  flex-direction: row;
  gap: 12px;

  &__circle {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 22px;
    border-radius: 25px;
  }

  &__circle--whatsapp {
    background-color: $whatsapp-base-color;
    color: $white;
  }

  &__circle--viber {
    background-color: $viber-base-color;
    color: $white;
  }
}
