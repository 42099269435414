@import "styles/variables";
@import "styles/mixins";

.fab {
  position: fixed;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $white;
  font-size: 22px;
  border-radius: 25px;
  background-color: $red;
  cursor: pointer;

  @include respond-to(below-md) {
    bottom: 30px;
    right: 30px;
  }

  @include respond-to(from-md) {
    bottom: 50px;
    right: 50px;
  }
}
