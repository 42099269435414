@import "styles/variables";
@import "styles/mixins";

.main {
  &__block {
    position: relative;

    @include respond-to(from-md) {
      padding: 0 55px;
      max-width: 1540px;
      margin: 56px auto 45px auto;
    }

    @include respond-to(below-md) {
      margin-top: 56px;
      margin-bottom: 45px;
      padding: 24px;
    }
  }

  &__block--fullsize {
    margin-left: 0;
    margin-right: 0;
    max-width: 100%;
    background-size: cover;

    @include respond-to(from-md) {
      padding: 75px 55px;
    }
  }

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $black;
    opacity: 0.5;
  }

  &__body {
    position: relative;
    max-width: 1540px;
    margin: 0 auto;
    padding: 0 55px;
  }

  &__title {
    color: $black;

    @include respond-to(from-md) {
      margin-bottom: 48px;
      font-size: 48px;
      line-height: 60px;
    }

    @include respond-to(below-md) {
      margin-bottom: 30px;
      font-size: 30px;
      line-height: 38px;
    }
  }

  &__text {
    color: $gray;
    font-size: 16px;
    line-height: 24px;
  }
}

.hero {
  display: flex;

  @include respond-to(from-md) {
    margin-bottom: 125px;
    flex-direction: row;
    justify-content: space-between;
    column-gap: 42px;
  }

  @include respond-to(below-md) {
    flex-direction: column;
    row-gap: 30px;
  }
}

.hero-disclaimer {
  margin: 32px 0 48px;

  &__link {
    margin-bottom: 24px;
    display: block;
    color: $black;
    text-decoration: none;
    font-size: 28px;
    font-weight: 400;
    line-height: 24px;
  }
}

.hero-body {
  @include respond-to(from-md) {
    width: 575px;
    min-width: 575px;
  }

  @include respond-to(below-md) {
    width: 100%;
    order: 2;
  }

  &__text {
    margin-bottom: 12px;
  }

  &__btn {
    @include respond-to(from-md) {
      margin-right: 8px;
    }

    @include respond-to(below-md) {
      margin-bottom: 12px;
      width: 100%;
    }
  }
}

.hero-cover {
  position: relative;
  width: 100%;

  @include respond-to(below-md) {
    order: 1;
  }

  &__img {
    object-fit: cover;
    border-radius: 24px;
    width: 100%;
  }

  &__background {
    position: absolute;
    left: 50px;
    bottom: -150px;
    z-index: -1;
  }
}

.carousel {
  &__container {
    position: relative;
    width: 100%;

    @include respond-to(below-md) {
      overflow: hidden;
      border-radius: 8px;
    }
  }

  &__card {
    width: 100%;

    @include respond-to(from-md) {
      height: 500px;
    }
  }

  &__img {
    object-fit: cover;
    border-radius: 24px;

    @include respond-to(from-md) {
      width: 500px;
      height: 800px;
    }

    @include respond-to(below-md) {
      width: 100%;
    }
  }
}

.advantages {
  display: flex;

  @include respond-to(from-md) {
    margin-top: 24px;
    flex-direction: row;
    justify-content: space-between;
    column-gap: 42px;
  }

  @include respond-to(below-md) {
    flex-direction: column;
    row-gap: 30px;
  }
}

.advantages-cover {
  position: relative;

  @include respond-to(from-md) {
    width: 575px;
    min-width: 575px;
  }

  @include respond-to(below-md) {
    width: 100%;
    order: 2;
  }

  &__img {
    object-fit: cover;
    border-radius: 24px;

    @include respond-to(from-md) {
      width: 500px;
      height: 800px;
    }

    @include respond-to(below-md) {
      width: 100%;
    }
  }

  &__background {
    position: absolute;
    left: 50px;
    bottom: -150px;
    z-index: -1;
  }
}

.advantages-list {
  margin: 30px -12px 0 -12px;
  display: flex;
  flex-wrap: wrap;
}

.advantages-list-item {
  padding: 12px;

  @include respond-to(from-md) {
    flex-basis: 50%;
  }

  @include respond-to(below-md) {
    flex-basis: 100%;
  }

  &__circle {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 22px;
    border-radius: 25px;
  }

  &__circle--green {
    background-color: rgba(12, 188, 135, 0.1);
    color: rgb(12, 188, 135);
  }

  &__circle--red {
    background-color: rgba(214, 41, 62, 0.1);
    color: rgb(214, 41, 62);
  }

  &__circle--orange {
    background-color: rgba(253, 126, 20, 0.1);
    color: rgb(253, 126, 20);
  }

  &__circle--blue {
    background-color: rgba(23, 162, 184, 0.1);
    color: rgb(23, 162, 184);
  }

  &__title {
    margin: 8px 0;
    color: $black;
    font-size: 21px;
    font-weight: 700;
    line-height: 26px;
  }

  &__text {
    color: $gray;
    font-size: 16px;
    line-height: 24px;
  }
}

.menu-list {
  margin: 30px -12px 0 -12px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @include respond-to(below-md) {
    gap: 24px;
  }
}

.menu-list-item {
  overflow: hidden;
  position: relative;
  padding: 24px 12px;
  height: 480px;
  display: flex;
  align-items: flex-end;
  border-radius: 24px;
  background-size: cover;
  box-shadow: inset 0 -100px 50px -25px #000000;
  text-decoration: none;

  @include respond-to(from-md) {
    flex-basis: 32%;
  }

  @include respond-to(below-md) {
    flex-basis: 100%;
  }

  &:hover .menu-list-item__body {
    @include respond-to(from-md) {
      transform: translateY(0px);
    }
  }

  &:hover .menu-list-item__text {
    @include respond-to(from-md) {
      opacity: 1;
    }
  }

  &__body {
    @include respond-to(from-md) {
      transform: translateY(40px);
      transition: all 0.25s ease-in-out;
    }
  }

  &__title {
    margin: 20px 0 8px 0;
    color: $white;
    font-weight: 700;
    line-height: 26px;

    @include respond-to(below-md) {
      font-size: 21px;
    }

    @include respond-to(from-md) {
      font-size: 28px;
    }
  }

  &__text {
    color: $white;
    line-height: 24px;

    @include respond-to(below-md) {
      font-size: 14px;
    }

    @include respond-to(from-md) {
      font-size: 16px;
      opacity: 0;
      transition: opacity 0.25s ease-in-out;
    }
  }
}

.delivery {
  &__container {
    position: relative;
    padding: 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    
    @include respond-to(below-md) {
      padding: 0;
      grid-template-columns: 1fr;
    }
    
  }
  
  &__background {
    position: relative;
    padding: 24px 0;
    background: rgb(251, 251, 251);
    
    @include respond-to(from-md) {
      padding: 75px 0;
      background: linear-gradient(90deg, rgb(251, 251, 251) 55%, #f7de3a 45%);
    }

    & > span {
      top: -16px;
      right: 32px;
      position: absolute;
      padding: 14px;
      font-size: 18px;
      line-height: 16px;
      color: #ffffff;
      font-weight: 700;
      text-transform: uppercase;
      border-radius: 1000px;
      background-color: #f7de3a;
      background-color: $black;

      @include respond-to(below-md) {
        display: none;
      }
    }
  }
  

  &__banner {
    position: relative;
    padding: 0 55px;
    width: 100%;
    height: 100%;
    margin-bottom: 0;

    @include respond-to(below-md) {
      padding: 24px;
    }
    

    &--left {
      // padding: 48px 139px;
      // background-color: red;
    }

    &--right {
      overflow: hidden;
      padding: 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;

    }
    
  }
  
  &__img {
    position: absolute;
    width: auto;
    height: 350px;
    top: 80px;
    right: 0;

    @include respond-to(below-md) {
      display: none;
    }
  }

  &__title {
    margin-bottom: 8px;
    color: $black;
    color: #191606;
    font-size: 32px;
    text-transform: uppercase;

    @include respond-to(below-md) {
      font-size: 21px;
      line-height: 28px;
    }

    @include respond-to(from-md) {
      font-size: 28px;
      line-height: 35px;
    }
  }

  &__text {
    margin-top: 12px;
    margin-bottom: 18px;
    color: $gray;
    color: #191606;
    font-size: 16px;
    line-height: 24px;

    @include respond-to(below-md) {
      text-align: center;
    }
  }

  &__text span {
    font-size: 20px;
    font-weight: 600;
    text-decoration: underline;
    text-underline-offset: 4px;
  }

  &__btn {
    margin-top: 10px;
    background-color: $black;
    border-radius: 50px;
    font-size: 18px;
    font-weight: 600;
    padding: 20px 40px;
    color: $black;
    background-color: #f7de3a;
    border-color: #f7de3a;

    @include respond-to(below-md) {
      font-size: 14px;
      margin: 0 auto;
      width: 100%;
    }
  }

  &__hero-text {
    margin-top: 20px;
    font-size: 50px;
    font-weight: 500;
    line-height: 1.25;
    text-transform: uppercase;
    margin-bottom: 12px;

    @include respond-to(below-md) {
      margin-top: 0;
      font-size: 38px;
      text-align: center;
      display: block;
      width: 100%;
    }
    
    &--colored {
      padding: 12px;
      color: $black;
      background-color: #f7de3a;
      
      @include respond-to(from-md) {
        margin-left: -12px;
        display: inline;
      }
    }
  }
}

.ymaps__container {
  margin: 0;
  padding: 0;
}

// special

.special-new-year {
  &__container {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }

  &__background {
    @include respond-to(below-md) {
      background-image: none !important;
    }
  }

  &__body {
    position: relative;
    padding: 56px;
    margin-left: auto;
    border-radius: 24px;

    @include respond-to(below-md) {
      padding: 36px;
      background-color: rgba(23, 162, 184, 0.75);
    }
    
    @include respond-to(from-md) {
      padding: 56px;
      width: 550px;
      background-color: $white;
    }
  }

  &__title {
    margin-bottom: 8px;

    @include respond-to(below-md) {
      color: $white;
      font-size: 21px;
      line-height: 28px;
    }

    @include respond-to(from-md) {
      color: $black;
      font-size: 28px;
      line-height: 35px;
    }
  }

  &__text {
    margin-bottom: 4px;
    color: $gray;
    font-size: 16px;
    line-height: 24px;

    @include respond-to(below-md) {
      color: $white;
    }
  }

  &__price {
    margin-top: 12px;
    margin-bottom: 4px;
    color: $primary;
    font-size: 22px;
    font-weight: 500;
    line-height: 24px;
    
    @include respond-to(below-md) {
      color: $white;
    }
  }
  
  &__btn {
    margin-top: 12px;
    margin-right: 4px;
    font-size: 13px;
    
    @include respond-to(below-md) {
      color: $white;
      background-color: rgb(23, 162, 184);
      border-color: rgb(23, 162, 184);
      font-size: 14px;
    }
  }
}
