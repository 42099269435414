@import "styles/variables";
@import "styles/mixins";

.menu {
  &__container {
    display: flex;
    @include respond-to(from-md) {
      height: 100vh;
    }
  }

  &__cover {    
    @include respond-to(from-md) {
      min-width: 550px;
      width: 550px;
      background-size: cover;
      background-position: center;
    }

    @include respond-to(below-md) {
      display: none;
    }
  }

  &__title {
    color: $black;

    @include respond-to(from-md) {
      margin-bottom: 48px;
      font-size: 48px;
      line-height: 60px;
    }

    @include respond-to(below-md) {
      margin-bottom: 30px;
      font-size: 30px;
      line-height: 38px;
    }
  }

  &__btn {
    @include respond-to(from-md) {
      margin-right: 14px;
    }

    @include respond-to(below-md) {
      margin-bottom: 12px;
      width: 100%;
    }
  }

  &__fab {
    
    @include respond-to(below-md) {
      position: fixed;
      padding-top: 10px;
      bottom: 0;
      left: 8px;
      background-color: $white;
    }
  }

  &__fab-spacer {
    
    @include respond-to(below-md) {
      height: 80px;
      display: block;
    }

    @include respond-to(from-md) {
      display: none;
    }
  }
}

.menu-list {
  
  @include respond-to(from-md) {
    overflow-y: auto;
    width: 100%;
    height: 100vh;
    padding: 55px;
  }

  @include respond-to(below-md) {
    padding: 24px;
  }
}

.menu-table {
  
  @include respond-to(from-md) {
    margin: 0 -32px 24px -32px;
  }

  @include respond-to(below-md) {
    margin: 0 -14px 24px -14px;
  }
  
  td {
    
    @include respond-to(from-md) {
      padding: 8px 32px;
    }
    
    @include respond-to(below-md) {
      padding: 8px 14px;
    }
  }

  &__title {
    color: $black;
    font-size: 21px;
    font-weight: 700;
    line-height: 26px;
    text-decoration: underline;
    text-decoration-color: $primary;
  }
}

.menu-table-item {
  &__title {
    font-size: 16px;
    font-weight: 500;
  }

  &__text {
    margin-top: 8px;
    color: $gray;
    font-size: 14px;
    display: inline-block;
    font-weight: 300;
  }
  
  &__weight {
    color: $gray;
    font-size: 14px;
    font-weight: 300;
    white-space: nowrap;
  }
}